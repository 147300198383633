<template>
	<div>
		<div class="to-back" @click="$router.back(-1)">
			<span class="back"><i class="iconfont icon-arrow-left-bold"></i>返回</span>
		</div>
		<div class="ser-form">
			<Form :model="formData" :label-width="100">
				<FormItem label="商品名称">
					<Tag size="medium" color="blue">{{formData.title}}</Tag>
				</FormItem>
				<FormItem label="套餐包类型">
					<div class="radio-box">
						<div class="radio-box-options">
							<span :class="'radio-box-item'+(selIndex===index?' radio-box-item-select':'')"
								v-for="(item,index) in data" :key="index"
								@click="changeIndex(index)">{{item.label}}</span>
						</div>
					</div>
				</FormItem>
				<FormItem label="资源包规格">
					<div class="radio-box">
						<div class="radio-box-options">
							<span class="radio-box-item disabled">{{formData.amount}}</span>
						</div>
					</div>
				</FormItem>
				<FormItem label="商品单价">
					<span style="color: #949999;">￥{{price}}</span>
				</FormItem>
				<FormItem label="购买数量">
					<InputNumber :precision="0" :disabled="formData.is_num==0?true:false" :max="999" :min="1"
						v-model="formData.qty" style="width: 152px;" @on-change="changeNum"></InputNumber>
						<span style="margin-left: 10px;color: #999999;">自购买一年内有效</span>
				</FormItem>
				<FormItem label="支付方式">
					<div class="pay_type">
						<!--            <div class="pay_item" :class="{'active':formData.payment==1}" @click="formData.payment=1">
              <i class="iconfont icon-zhifubao"></i>
              <span class="text">支付宝支付</span>
              <i v-if="formData.payment==1" class="iconfont icon-xuanze"></i>
            </div> -->
						<div class="pay_item" :class="{'active':formData.payment==2}" @click="formData.payment=2">
							<i class="iconfont icon-weixinzhifu"></i>
							<span class="text">微信支付</span>
							<i v-if="formData.payment==2" class="iconfont icon-xuanze"></i>
						</div>
					</div>
				</FormItem>
				<FormItem :label-width="0">
					<div class="price-active">
						<div class="price-fee">
							<div class="price-fee-item">
								<span>总配置费用</span>
								<span class="price-fee-amount"><strong>￥{{formData.price}}</strong></span>
							</div>
							<div class="price-fee-item" v-if="formData.discount">
								<span class="price-fee-title">新用户首购限时优惠</span>
								<span>
									<span class="price-fee-label">节省</span>
									<span class="price-fee-num">￥{{formData.discount}}</span>
								</span>

							</div>
						</div>
						<span class="price-btn" @click="buy_submit">确认购买</span>
					</div>
				</FormItem>
			</Form>
		</div>
		<Spin size="large" fix v-if="spin_show">
			<Icon type="ios-loading" :size='18' class="demo-spin-icon-load"></Icon>
			<div>订单生成中</div>
		</Spin>
		<Modal :mask-closable="false" v-model="pay_show" @on-cancel="cancel_pay()" class-name="modal" :transfer="false"
			:footer-hide="true" width="250">
			<div style="text-align: center;width: 100%;font-weight: bold;">
				微信支付
			</div>
			<vue-qr :text="code_url" :size="220"></vue-qr>
		</Modal>
	</div>
</template>

<script>
	import vueQr from 'vue-qr'
	export default {
		name: "AiPayment",
		components: {
			vueQr
		},
		data() {
			return {
				id: null,
				type: null,
				selIndex: 0,
				pay_show: false,
				timeout: null,
				spin_show: false,
				formData: {
					id: null,
					title: '',
					type: null,
					amount: null,
					payment: 2,
					price: null,
					qty: 1,
					is_num: 0,
					discount: null
				},
				price: 0,
				data: [],
				code_url: '',
			}
		},
		//离开当前页面触发，作用于获取微信订单状态
		destroyed() {
			clearInterval(this.timeout)
		},
		mounted() {
			this.id = this.$route.query.id;
			this.type = this.$route.query.type;
			if (this.id && this.type) this.init();
		},
		methods: {
			init() {
				var _this = this;
				this.requestApi('/adm/get_apidtl', {
					id: this.id,
					type: this.type
				}).then(function(res) {
					if (res.status) {
						_this.data = res.data;
						if (res.data.length) {
							_this.formData.amount = res.data[0].amount;
							_this.formData.title = res.data[0].title;
							_this.formData.price = res.data[0].price;
							_this.formData.id = res.data[0].id;
							_this.formData.type = res.data[0].type;
							_this.price = res.data[0].price;
							_this.formData.is_num = res.data[0].is_num;
						}
					}
				})
			},
			changeIndex(index) {
				if (this.selIndex === index) return false;
				this.selIndex = index;
				this.formData.amount = this.data[index].amount;
				this.formData.price = this.data[index].price;
				this.formData.type = this.data[index].type;
				this.formData.id = this.data[index].id;
				this.price = this.data[index].price;
				this.formData.is_num = this.data[index].is_num;
				this.formData.qty = this.formData.is_num ? this.formData.qty : 1;
				this.changeNum();
			},
			changeNum() {
				var price = this.price,
					qty = this.formData.qty;
				this.formData.price = this.formatFloat(price * qty, 2);
			},
			//点击购买
			buy_submit() {
				var _this = this
				this.spin_show = true
				this.requestApi('/adm/add_ai_order', {
					data: this.formData
				}).then(
					(res) => {
						if (res.code == 200) {
							_this.spin_show = false
							_this.code_url = res.data.code_url
							_this.pay_show = true
							this.timeout = setInterval(function() {
								_this.requestApi('/adm/get_order_status', {
									order_number: res.data.order_number
								}).then(
									(res) => {
										if (res.data == 1) {
											clearInterval(_this.timeout)
											_this.$Message.success('支付成功')
											_this.pay_show = false
											setTimeout(function() {
												_this.$router.go(-1)
											}, 100)
										}
									}
								)
							}, 500)
						} else {
							_this.$Message.error('服务器繁忙')
						}
					}
				)
			},
			//点击取消
			cancel_pay() {
				clearInterval(this.timeout)
			}
		}
	}
</script>

<style scoped>
	.to-back {
		padding: 8px;
		background-color: #FFFFFF;
		margin-bottom: 12px;
	}

	.back {
		display: inline-block;
		padding: 0 10px;
		height: 32px;
		line-height: 32px;
		border: 1px solid #EEEEEE;
		cursor: pointer;
	}

	.back .icon-arrow-left-bold {
		font-size: 12px;
		color: #999999;
		margin-right: 8px;
	}

	.ser-form {
		padding: 16px;
		background-color: #FFFFFF;
	}

	.price-active {
		margin-top: 25px;
	}

	.radio-box-item {
		min-width: 152px;
		text-decoration: none;
		margin-right: 10px;
		margin-bottom: 10px;
		position: relative;
		display: inline-block;
		font-weight: 400;
		white-space: nowrap;
		text-align: center;
		background-image: none;
		border: 1px solid #e5e5e5;
		box-shadow: none;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		touch-action: manipulation;
		height: 32px;
		line-height: 32px;
		padding: 0 15px;
		font-size: 12px;
		border-radius: 0;
		color: #262626;
		cursor: pointer;
	}

	.radio-box-item-select {
		border: 1px solid #00c1d0;
		color: #373d41;
		border-color: #b2d4ef;
		background: rgba(0, 112, 204, .15);
	}

	.disabled {
		background-color: #ebebeb;
		color: #949999;
		cursor: default;
		border-color: #ebebeb;
	}

	.radio-box-tips {
		font-size: 12px;
		color: #787e80;
		line-height: 1.5;
		margin-top: 10px;
	}

	.demo-spin-icon-load {
		animation: ani-demo-spin 1s linear infinite;
	}

	.price-fee {
		font-size: 12px;
		display: inline-block;
		padding: 0 35px;
		vertical-align: middle;
		text-align: right;
	}

	.price-fee-item {
		line-height: 1.7;
	}

	.price-fee-amount {
		font-family: PingFangSC-Regular;
		margin-left: 5px;
		line-height: 17px;
		font-size: 24px;
		color: #ff8a00;
		letter-spacing: 0;
		text-align: right;
	}

	.price-fee-title {
		background: rgba(201, 240, 201, .5);
		color: #35b34a;
	}

	.price-fee-label {
		margin-left: 5px;
		color: #35b34a;
	}

	.price-fee-num {
		font-weight: 400;
		margin-left: 5px;
		color: #35b34a;
	}

	strong {
		font-weight: 500;
	}

	.price-btn {
		background: #ff8a00;
		min-width: 140px;
		padding: 0 10px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		color: #fff;
		display: inline-block;
		margin-left: 12px;
		cursor: pointer;
	}

	.pay_type {
		display: flex;
	}

	.pay_item {
		margin-right: 10px;
		display: flex;
		justify-content: center;
		width: 150px;
		height: 50px;
		line-height: 50px;
		border: 2px solid #e4e4e4;
		cursor: pointer;
		text-align: center;
		font-size: 18px;
		position: relative;
	}

	.active {
		border: 2px solid #ff4544;
	}

	.icon-xuanze {
		position: absolute;
		top: -18px;
		right: -2px;
		color: #ff4544;
	}

	.pay_item .icon-zhifubao {
		color: #039feb;
		font-size: 26px;
		margin-right: 5px;
	}

	.pay_item .icon-weixinzhifu {
		font-size: 26px;
		color: #41b035;
		margin-right: 5px;
	}

	.pay_item .text {
		display: inline-block;
		line-height: 48px;
	}
</style>
